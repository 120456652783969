import React from "react"
import Layout from "./../../../components/layouts/Request"
import Seo from "./../../../components/layouts/Seo"
import RequestLeftCol from "./../../../components/layouts/partials/pages/request/RequestLeftCol"
import RequestRightCol from "./../../../components/layouts/partials/pages/request/RequestRightCol"
import Wizard from "./../../../components/forms/Wizard"
import Input from "./../../../components/forms/Input"
import CheckboxCard from "./../../../components/forms/CheckboxCard"
import FormMainData from "./../../../components/layouts/partials/pages/request/MainData"

export default function Page() {
  const svg = {
    umschuldung: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="inline"
        width={64}
        height={64}
        viewBox="0 0 24 24"
        strokeWidth="1"
        stroke="#000"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <line x1={9} y1={14} x2={15} y2={8} />
        <circle cx="9.5" cy="8.5" r=".5" fill="currentColor" />
        <circle cx="14.5" cy="13.5" r=".5" fill="currentColor" />
        <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2" />
      </svg>
    ),
    auto: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="inline"
        width={64}
        height={64}
        viewBox="0 0 24 24"
        strokeWidth="1"
        stroke="#000"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <circle cx={7} cy={17} r={2} />
        <circle cx={17} cy={17} r={2} />
        <path d="M5 17h-2v-6l2-5h9l4 5h1a2 2 0 0 1 2 2v4h-2m-4 0h-6m-6 -6h15m-6 0v-5" />
      </svg>
    ),
    frei: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="inline"
        width={64}
        height={64}
        viewBox="0 0 24 24"
        strokeWidth="1"
        stroke="#000"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <path d="M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12" />
        <path d="M20 12v4h-4a2 2 0 0 1 0 -4h4" />
      </svg>
    ),
  }

  return (
    <Layout>
      <Seo
        title="Privatkredit anfragen"
        description="Wir finden den passenden Privatkredit für Dich."
        path="/finanzierungen/privatkredit/anfragen/"
      />
      <section>
        <div className="flex flex-wrap">
          <div className="w-full lg:w-8/12 lg:order-2 max-w-screen-lg p-8 lg:p-16">
            <RequestRightCol>
              <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold">
                Privatkredit anfragen
              </h1>
              <form
                action="https://formbackend.dierda.de/anfrage.php"
                method="POST"
              >
                <input type="hidden" name="Anfrage" value="Privatkredit" />

                <Wizard>
                  <div>
                    <div className="mt-8 mb-4">
                      <h3 className="text-lg leading-6 font-bold text-gray-900">
                        Wie hoch soll das Darlehen sein?
                      </h3>
                      <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                        Diese Informationen sind wichtig, damit wir ungefähr
                        wissen, was Du benötigst.
                      </p>
                    </div>
                    <div className="mb-4">
                      <Input
                        type="number"
                        pattern="[0-9]+([\.,][0-9]+)?"
                        label="Gewünschte Summe in €"
                        id="summe"
                        name="Summe"
                        placeholder="1.000,00"
                        required
                      />
                    </div>

                    <div className="mb-4">
                      <Input
                        type="number"
                        label="Gewünschte Laufzeit in Monaten"
                        id="laufzeit"
                        name="Laufzeit"
                        placeholder="12"
                        required
                      />
                    </div>
                  </div>

                  <div>
                    <div className="mt-8 mb-4">
                      <h3 className="text-lg leading-6 font-bold text-gray-900">
                        Wofür benötigst Du das Darlehen?
                      </h3>
                      <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                        Klicke kurz an, ob die folgenden Optionen für Dich
                        relevant sind. Diese Informationen sind wichtig, damit
                        wir ungefähr wissen, was Du benötigst.
                      </p>
                    </div>
                    <div className="grid gap-4 grid-cols-1 sm:grid-cols-3 mb-6">
                      <div>
                        <CheckboxCard
                          label="Umschuldung"
                          name="Nutzen"
                          value="Umschuldung"
                          id="umschuldung"
                          svg={svg.umschuldung}
                        />
                      </div>
                      <div>
                        <CheckboxCard
                          label="Autokredit"
                          name="Nutzen"
                          value="Autokredit"
                          id="autokredit"
                          svg={svg.auto}
                        />
                      </div>
                      <div>
                        <CheckboxCard
                          label="Freie Verwendung"
                          name="Nutzen"
                          value="Freie Verwendung"
                          id="freie-verwendung"
                          svg={svg.frei}
                        />
                      </div>
                    </div>
                  </div>
                  <FormMainData />
                </Wizard>
              </form>
            </RequestRightCol>
          </div>
          <div className="w-full lg:w-4/12 lg:order-1 bg-gray-100 p-8 lg:p-16 ">
            <RequestLeftCol />
          </div>
        </div>
      </section>
    </Layout>
  )
}
